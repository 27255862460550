import React from "react"
import PropTypes from "prop-types"

export default function TableHeader({columns}) {
	return (
		<thead>
			<tr className='flex bg-extendedPurple-five'>
				{columns.map(column => (
					<th
						key={column.id}
						className={`inline-block w-40 py-2 px-4 text-md text-extendedCharcoal-one 
						font-normal uppercase tracking-wider ${column.textAlign}`}>
						{column.name}
					</th>
				))}
			</tr>
		</thead>
	)
}

TableHeader.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	)
}
