import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {useLocation} from "react-router-dom"
import SvgIcon from "../assets/icons"

const ROUTES = [
	{
		id: 1,
		to: "/referral",
		name: "New Referral",
		activeIcon: require("../assets/icons/add-purple.svg"),
		icon: require("../assets/icons/add-grey.svg")
	},
	{
		id: 2,
		to: "/",
		name: "Dashboard",
		activeIcon: require("../assets/icons/home-purple.svg"),
		icon: require("../assets/icons/home-grey.svg")
	},
	{
		id: 3,
		to: "/managers",
		name: "Managers",
		activeIcon: require("../assets/icons/notebook-purple.svg"),
		icon: require("../assets/icons/notebook-grey.svg")
	},
	{
		id: 4,
		to: "/users",
		name: "All Users",
		activeIcon: require("../assets/icons/user-purple.svg"),
		icon: require("../assets/icons/user-grey.svg")
	}
]

export default function SideBar() {
	const [isOpen, setIsOpen] = useState(false)
	const isMobile = window.innerWidth < 768
	const location = useLocation()

	useEffect(() => {
		if (!isMobile) {
			setIsOpen(true)
		}
	}, [isMobile])

	return (
		<div className='fixed z-10 md:z-0 md:relative w-full md:w-64 xl:w-1/5 md:h-full bg-backgrounds-white shadow md:py-10 font-body'>
			<div className='flex items-center mt-4 md:mt-10 px-4 pb-4'>
				<button
					className={`p-1 outline-none ${isMobile ? "" : "hidden"}`}
					onClick={() => setIsOpen(!isOpen)}
					onBlur={() => setTimeout(() => setIsOpen(false), 0)}>
					{!isOpen ? (
						<SvgIcon type='menu' />
					) : (
						<SvgIcon type='close' />
					)}
				</button>
				<Link to='/' className='w-full'>
					<img
						className='w-32 mx-auto'
						src={require("../assets/images/logo-workable.png")}
						alt='workable logo'
					/>
				</Link>
			</div>
			{isOpen && (
				<ul className='mt-4 md:mt-16'>
					{ROUTES.map(route => {
						const active =
							route.to === "/"
								? location.pathname === route.to
								: location.pathname.includes(route.to)

						const activeClass = active
							? "bg-backgrounds-fadedPurple text-primary-purple border-r-2 border-primary-purple"
							: "text-primary-charcoal"
						const hoverClass =
							"hover:shadow hover:bg-backgrounds-fadedPurple hover:text-primary-purple"
						return (
							<li
								key={route.id}
								className={`flex items-center px-4 xl:px-10 py-2 xl:py-3
								 ${activeClass} ${hoverClass} select-none`}>
								<img
									className='w-4 h-4 mr-4 fill-current text-black'
									src={active ? route.activeIcon : route.icon}
									alt='icon'
								/>
								<Link
									className='inline-block w-full px-1 text-lg'
									to={route.to}>
									{route.name}
								</Link>
							</li>
						)
					})}
				</ul>
			)}
		</div>
	)
}
