import React, {useEffect} from "react"
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory,
	Redirect
} from "react-router-dom"
import {toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import "./styles/tailwind.css"
import Dashboard from "./views/DashboardView"
import Referral from "./views/ReferralView"
import Sidebar from "./views/Sidebar"
import Managers from "./views/AllManagersView"
import CaseManager from "./views/CaseManagerView"
import UserView from "./views/UserView"
import AllUserView from "./views/AllUsersView"

const hasKey = () => {
	try {
		const key = window.localStorage.getItem("workable-key")
		return key === "saved"
	} catch (error) {
		console.error(error)
	}
}

export default function App() {
	useEffect(() => {
		// Call it once in your app. At the root of your app is the best place
		toast.configure({
			hideProgressBar: false
		})
	}, [])

	return (
		<div className='w-full h-screen bg-extendedPurple-six'>
			<Router>
				<Switch>
					<Route path='/sign-in'>
						<SignIn />
					</Route>
					<Route path='/'>
						<PrivateRoutes />
					</Route>
				</Switch>
			</Router>
		</div>
	)
}

function PrivateRoutes() {
	const loggedIn = hasKey()
	return loggedIn ? (
		<Route path='/'>
			<Home />
		</Route>
	) : (
		<Redirect to='/sign-in' />
	)
}

function Home() {
	return (
		<div className='w-full h-full md:flex'>
			<Sidebar />
			<Switch>
				<Route exact path='/'>
					<Dashboard />
				</Route>
				<Route exact path='/managers'>
					<Managers />
				</Route>
				<Route path='/managers/:id'>
					<CaseManager />
				</Route>
				<Route exact path='/users'>
					<AllUserView />
				</Route>
				<Route path='/users/:id'>
					<UserView />
				</Route>
				<Route path='/referral'>
					<Referral />
				</Route>
			</Switch>
		</div>
	)
}

export function SignIn() {
	const [password, setPassword] = React.useState("")
	const history = useHistory()

	return (
		<div className='w-full flex'>
			<div className='w-full md:w-1/2 h-screen p-10 lg:pr-24 lg:pl-32 lg:pt-40'>
				<img
					className='w-full max-w-md mt-10 mx-auto'
					src={require("./assets/images/logo-workable.png")}
					alt='workable logo'
				/>
				<form
					className='max-w-md mt-10 mx-auto'
					onSubmit={event => {
						event.preventDefault()
						if (password === "secret") {
							try {
								window.localStorage.setItem(
									"workable-key",
									"saved"
								)
								history.push("/")
							} catch (error) {
								console.error(error)
							}
						}
					}}>
					<div className='bg-white shadow-md rounded px-4 py-4'>
						<label
							htmlFor='password'
							className='text-xs text-extendedCharcoal-one block pb-1'>
							Password
						</label>
						<input
							id='password'
							type='password'
							name='secret'
							value={password}
							onChange={event => setPassword(event.target.value)}
							className='w-full py-2 text-sm text-gray-700 border-b border-extendedPurple-five leading-tight focus:outline-none'
							placeholder='*********'
						/>
					</div>

					<button className='w-full h-10 mt-4 rounded bg-extendedPurple-dark text-white'>
						Sign in
					</button>
				</form>
			</div>
			<div className='hidden md:block md:w-1/2 h-screen p-10 lg:pr-32 lg:pl-24 lg:pt-40 bg-backgrounds-white'>
				<h1 className='text-4xl text-primary-charcoal'>
					Workable by Uprise
				</h1>
				<p className='mt-5 max-w-md text-lg text-primary-charcoal text-extendedCharcoal-one'>
					An evidence based, digital mental resilience program to
					assist injured workers return to work earlier.
				</p>
				<img
					src={require("./assets/images/login-splash.svg")}
					alt='mind fullness'
					className='w-full max-w-lg mt-10'
				/>
			</div>
		</div>
	)
}
