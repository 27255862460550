import React from "react"
import PropTypes from "prop-types"
import {
	ResponsiveContainer,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip
} from "recharts"
// colors
import {extended} from "uprise-uikit/colors"

export default function Graph({primary, data}) {
	const keys = Object.keys(data[0])
	const XAxisKey = "date"
	const YAxisKey = keys.includes("referrals") ? "referrals" : "signups"

	const stroke = primary ? extended.electricPurple.one : extended.blue.one
	const fill = primary ? extended.electricPurple.four : extended.blue.four
	return (
		<ResponsiveContainer width='100%' height={400}>
			<AreaChart
				data={data}
				margin={{
					top: 10,
					right: 30,
					left: 0,
					bottom: 0
				}}>
				<XAxis dataKey={XAxisKey} />
				<YAxis dataKey={YAxisKey} />
				<Tooltip />
				<Area
					type='monotone'
					dataKey={YAxisKey}
					stroke={stroke}
					fill={fill}
				/>
			</AreaChart>
		</ResponsiveContainer>
	)
}

Graph.propTypes = {
	primary: PropTypes.bool,
	data: PropTypes.array.isRequired
}

Graph.defaultProps = {
	primary: false
}
