import React from "react"
import PropTypes from "prop-types"

export default function Card({small, ...restProps}) {
	return small ? <SmallCard {...restProps} /> : <LargeCard {...restProps} />
}

Card.propTypes = {
	small: PropTypes.bool
}

Card.defaultProps = {
	small: false
}

function LargeCard({label, value, type, className, percentage}) {
	const bgClass =
		type === "primary"
			? "bg-gradient-purple"
			: type === "secondary"
			? "bg-gradient-blue"
			: "bg-backrounds-white"
	const textColor = type === "none" ? "text-primary-purple" : "text-white"

	return (
		<div className={`w-full md:w-56 mb-12 ${className}`} data-tip={label}>
			<div
				className={`p-6 ${bgClass} ${textColor} text-center rounded-sm shadow-lg `}>
				<span className='text-6xl'>
					{percentage ? `${value}%` : value}
				</span>
			</div>
			<p className='mt-2 md:mt-4 text-lg text-extendedCharcoal-one text-center truncate'>
				{label}
			</p>
		</div>
	)
}

LargeCard.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	type: PropTypes.string
}

LargeCard.defaultProps = {
	type: "none"
}

function SmallCard({label, value, primary, className, percentage}) {
	const textColor = primary ? "text-primary-purple" : "text-extendedBlue-one"
	const borderClass = primary
		? "border-t-8 border-extendedPurple-one"
		: "border-t-8 border-extendedBlue-two"

	return (
		<div className={`w-full md:w-56 mb-12 ${className}`}>
			<div
				className={`p-6 bg-white ${textColor} ${borderClass} text-center rounded-sm shadow-lg `}>
				<span className='text-6xl'>
					{percentage ? `${value}%` : value}
				</span>
			</div>
			<p className='mt-2 md:mt-4 text-lg text-extendedCharcoal-one text-center truncate'>
				{label}
			</p>
		</div>
	)
}

SmallCard.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	primary: PropTypes.bool
}

SmallCard.defaultProps = {
	primary: false
}
