import ApolloClient from "apollo-boost"
// TODO: Update uri for prod
const GRAPHQL_SERVER_URI = {
    development:
        // "https://testing-app.uprise.co/graphql" ||
        "http://localhost:8080/graphql",
    production: "https://app.uprise.co/graphql"
}

const client = new ApolloClient({
    uri: GRAPHQL_SERVER_URI[process.env.NODE_ENV]
})

export default client
