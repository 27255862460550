import React from "react"
import {useHistory} from "react-router-dom"
import PropTypes from "prop-types"

export default function UserRow({
	id,
	name,
	policyNumber,
	referralDate,
	signupDate,
	modulesCompleted,
	completionDate
}) {
	const history = useHistory()
	const even = id % 2 === 0
	const modules = modulesCompleted.map(m => m.title).join(", ")
	const handleOnClick = () => {
		if (id) {
			history.push(`/users/${id}`)
		} else {
			alert(`User has not signed up yet!`)
		}
	}

	return (
		<tr
			className={`font-body ${
				even ? "bg-backgrounds-white" : "bg-backgrounds-fadedPurple"
			}`}>
			<td
				className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body cursor-pointer hover:text-extendedPurple-one'
				onClick={handleOnClick}>
				{name}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{policyNumber}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-right text-md text-primary-charcoal font-body'>
				{referralDate}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-right text-md text-primary-charcoal font-body'>
				{signupDate}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{modulesCompleted.length ? modules : "none"}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-right text-md text-primary-charcoal font-body'>
				{completionDate}
			</td>
		</tr>
	)
}

UserRow.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	referralDate: PropTypes.string.isRequired,
	signupDate: PropTypes.string.isRequired,
	modulesCompleted: PropTypes.string.isRequired,
	completionDate: PropTypes.string.isRequired
}

UserRow.defaultProps = {
	signupDate: "---",
	completionDate: "---",
	modulesCompleted: []
}
