const USER_TABLE_COLUMNS = [
	{id: 1, name: "Name", textAlign: "text-left"},
	{id: 2, name: "Policy #", textAlign: "text-left"},
	{id: 3, name: "Referral Date", textAlign: "text-right"},
	{id: 4, name: "Signup Date", textAlign: "text-right"},
	{id: 5, name: "Modules Completed", textAlign: "text-left"},
	{id: 6, name: "Completion Date", textAlign: "text-left"}
]

const MANAGER_TABLE_COLUMNS = [
	{id: 1, name: "Rank", textAlign: "text-left"},
	{id: 2, name: "Name", textAlign: "text-left"},
	{id: 3, name: "Referrals", textAlign: "text-left"},
	{id: 4, name: "Signups", textAlign: "text-left"},
	{id: 5, name: "Programs completed", textAlign: "text-left"}
]

const STATUS_AT_INTAKE = {
	// Off work due to symptoms
	OFF_WORK: "Off work",
	// At work but output limited
	AT_WORK_LIMITED_OUTPUT: "At work limited output",
	// Not working
	NOT_WORKING: "Not working",
	// Other
	OTHER: "Other"
}

export {USER_TABLE_COLUMNS, MANAGER_TABLE_COLUMNS, STATUS_AT_INTAKE}
