import React from "react"
import {useQuery} from "@apollo/react-hooks"
import {gql} from "apollo-boost"
import UserRow from "../components/UserRow"
import Loader from "../components/Loader"
import Note from "../components/Note"

const ALL_USERS = gql`
	{
		rtwUsers {
			id
			signupDate
			referralDate
			modulesCompleted {
				completedWhen
				totalExercises
				completedExercises
				completed
				title
				subText
			}
			referral {
				id
				name
				email
				phone
				policyNumber
			}
		}
	}
`
export default function AllUserView() {
	const {loading, error, data} = useQuery(ALL_USERS)
	if (loading) return <Loader />
	if (error) {
		return (
			<div className='w-full h-full p-6 pt-20'>
				<Note type='error' message={`Failed to load users`} />
			</div>
		)
	}

	return (
		<div className='w-full h-full p-6 pt-8 overflow-auto'>
			<h1 className='mb-10 text-2xl font-body text-primary-charcoal'>
				All Users{" "}
			</h1>
			<div className='overflow-x-auto'>
				<table className='bg-white rounded-lg shadow'>
					<tbody>
						<tr className='flex bg-extendedPurple-five'>
							<th className='inline-block w-40 py-2 px-4 text-left text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Name
							</th>
							<th className='inline-block w-40 py-2 px-4 text-left text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Policy #
							</th>
							<th className='inline-block w-40 py-2 px-4 text-right text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Referral date
							</th>
							<th className='inline-block w-40 py-2 px-4 text-right text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Signup date
							</th>
							<th className='inline-block w-40 py-2 px-4 text-left text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Modules completed
							</th>
							<th className='inline-block w-40 py-2 px-4 text-right text-md text-extendedCharcoal-one font-normal uppercase tracking-wider'>
								Completion date
							</th>
						</tr>
						{data &&
							data.rtwUsers.map(user => (
								<UserRow
									key={user.id}
									id={user.referral.id}
									signupDate={user.signupDate}
									referralDate={user.referralDate}
									modulesCompleted={user.modulesCompleted}
									{...user.referral}
								/>
							))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
