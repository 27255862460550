import React from "react"
import { useParams, useHistory } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import formatDistance from "date-fns/formatDistance"
import Note from "../components/Note"
import Loader from "../components/Loader"
import TherapistNote from "../components/TherapistNote"
import { STATUS_AT_INTAKE } from "../utils/constants"

const USER = gql`
    query user($id: ID!) {
        rtwUserById(id: $id) {
            id
            signupDate
            referralDate
            programCompletionDate
            name
            email
            phone
            bookingCount
            modulesCompleted {
                completedWhen
                totalExercises
                completedExercises
                completed
                title
                subText
            }
            bookings {
                start
                end
                coach
                purpose
                coachName
            }
            programScores {
                taskId
                score
                surveyId
                date
            }
            referral {
                id
                status
                notes
                caseManagerId
                policyNumber
            }
            caseManager {
                name
            }
            therapistNotes {
                title
                content
            }
            therapist {
                name
                photos
            }
            downstreamReferrals {
                type
                date
            }
        }
    }
`

export default function UserView() {
    const params = useParams()
    const history = useHistory()
    const { loading, error, data } = useQuery(USER, {
        variables: { id: params.id }
    })

    if (loading) return <Loader />
    if (error) {
        return (
            <div className="w-full h-full p-6 pt-20 overflow-auto">
                <Note type="error" message={`Failed to load user`} />
            </div>
        )
    }
    const user = data && data.rtwUserById

    if (user) {
        return (
            <div className="w-full h-full p-6 pt-8 overflow-auto">
                <h1 className="text-2xl text-primary-charcoal font-semibold">
                    {user.name}
                </h1>
                <UserInfoSection title={"details"}>
                    <div className="w-full md:w-1/2">
                        <Info label="Email" value={user.email} />
                        <Info label="Phone number" value={user.phone} />
                        <Info
                            label="Case Manager"
                            value={user.caseManager.name}
                            handleOnClick={() =>
                                history.push(
                                    `/managers/${user.referral.caseManagerId}`
                                )
                            }
                        />
                        <Info
                            label="Status at Intake"
                            value={STATUS_AT_INTAKE[user.referral.status]}
                        />
                        <Info label="Uprise Therapist" value="Jeremy" />
                    </div>
                    <div className="w-full md:w-1/2">
                        <Info label="Referral Date" value={user.referralDate} />
                        <Info label="Signup Date" value={user.signupDate} />
                        <Info
                            label="Program completion Date"
                            value={user.programCompletionDate || "---"}
                        />
                        <Info
                            label="Back to work Date"
                            value={user.backToWorkDate || "---"}
                        />
                    </div>
                </UserInfoSection>
                <UserInfoSection title={"Score"}>
                    {user.programScores.length ? (
                        user.programScores.map(s => (
                            <div key={s.taskId} className="w-full md:w-1/2">
                                <Info label="Survey name" value={s.taskId} />
                                <Info label="Date" value={s.date} />
                                <Info label="Score" value={s.score} />
                            </div>
                        ))
                    ) : (
                        <Note
                            type="warning"
                            message="No surveys has been completed"
                        />
                    )}
                </UserInfoSection>

                <UserInfoSection title={"Modules completed"}>
                    <div className="w-full md:w-1/2">
                        {user.modulesCompleted.map(m => (
                            <Info
                                key={m.title}
                                label={m.title}
                                value={m.completedWhen}
                            />
                        ))}
                    </div>
                </UserInfoSection>

                <UserInfoSection title={"Coaching calls"}>
                    <div className="w-full md:w-1/2">
                        {user.bookings.map((b, i) => (
                            <Info
                                key={b.start}
                                label={`${b.purpose} ${i + 1}`}
                                value={b.start}
                            />
                        ))}
                    </div>
                </UserInfoSection>

                <UserInfoSection title={"Therapist notes"}>
                    {user.therapistNotes.map(note => (
                        <TherapistNote
                            key={note.title}
                            therapist={user.therapist}
                            therapistNote={note}
                        />
                    ))}
                </UserInfoSection>
                <UserInfoSection title="Downstream referrals">
                    <DownstreamReferrals referrals={user.downstreamReferrals} />
                </UserInfoSection>
            </div>
        )
    } else {
        return (
            <div className="w-full h-full p-6 pt-8 overflow-auto">
                <h1 className="text-2xl text-primary-charcoal font-semibold">
                    👻 User has not signed up yet
                </h1>
            </div>
        )
    }
}

UserView.propTypes = {}

function Info({ label, value, handleOnClick }) {
    return (
        <p className="mb-2 px-2 py-1 text-md text-extendedCharcoal-one capitalize">
            {`${label ? `${label}: ` : ""}`}
            <span
                onClick={handleOnClick}
                className={`pl-2 text-primary-charcoal ${
                    handleOnClick ? "cursor-pointer hover:text-blue-700" : ""
                }`}
            >
                {value}
            </span>
        </p>
    )
}

Info.defaultProps = {
    handleOnClick: null
}

function UserInfoSection({ title, children }) {
    return (
        <div className="mt-10 font-body">
            <div className="bg-gradient-purple px-4 py-2 rounded-sm shadow-md">
                <p className="text-lg text-white uppercase">{title}</p>
            </div>
            <div className="mt-4 md:flex">{children}</div>
        </div>
    )
}

function DownstreamReferrals(props) {
    return (
        <div className="w-full px-2 py-1">
            <div className="flex flex-wrap">
                {props.referrals.length ? (
                    props.referrals.map(referral => (
                        <div key={referral.date} className="mr-4 mb-6">
                            <span className="mr-2 px-4 py-2 rounded-full bg-green-200 text-green-800 ">
                                {referral.type}
                            </span>
                            <span>
                                {formatDistance(
                                    new Date(referral.date),
                                    new Date(),
                                    { addSuffix: true }
                                )}
                            </span>
                        </div>
                    ))
                ) : (
                    <Note type="warning" message="None requested yet!" />
                )}
            </div>
        </div>
    )
}
