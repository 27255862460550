import React, {useState, useEffect, Fragment} from "react"
import {useMutation, useQuery} from "@apollo/react-hooks"
import {gql} from "apollo-boost"
import ReactTooltip from "react-tooltip"
import {toast} from "react-toastify"

import TextInput from "../components/TextInput"
import Loader from "../components/Loader"
import Note from "../components/Note"
import {STATUS_AT_INTAKE} from "../utils/constants"
import {usePrevious} from "../utils/customHooks"

const CREATE_REFERRAL = gql`
	mutation NewReferral($input: NewReferralInput!) {
		newReferral(input: $input) {
			id
		}
	}
`

const ALL_MANAGERS = gql`
	{
		caseManagers {
			id
			name
		}
	}
`
const STATUS_TYPES = Object.keys(STATUS_AT_INTAKE).map((key, index) => ({
	id: index,
	value: key,
	label: STATUS_AT_INTAKE[key]
}))
const isDev = process.env.NODE_ENV === "development"

export default function CreateReferralView() {
	const [name, setName] = useState(isDev ? "Brain reynolds" : "")
	const [email, setEmail] = useState(isDev ? "brain.reynolds" : "")
	const [phone, setPhone] = useState(isDev ? "7896541023" : "")
	const [policyNumber, setPolicyNumber] = useState(
		isDev ? `new_${Math.random().toPrecision(5)}_MLC` : ""
	)
	const [status, setStatus] = useState(STATUS_TYPES[0].value)
	const [notes, setNotes] = useState(isDev ? "Need help create!" : "")
	const [caseManagerId, setCaseManagerId] = useState("")

	const {loading, error, data: allManagersData} = useQuery(ALL_MANAGERS)

	const [
		newReferral,
		{
			// called,
			data: createReferralData
			// error: createReferralError,
			// loading: createReferralLoading
		}
	] = useMutation(CREATE_REFERRAL)

	const prevCreateReferralData = usePrevious(createReferralData)
	// Reset form when new referral has created
	useEffect(() => {
		if (
			prevCreateReferralData?.newReferral?.id !==
			createReferralData?.newReferral?.id
		) {
			// reset form
			setName("")
			setEmail("")
			setPhone("")
			setPolicyNumber("")
			setNotes("")
			setCaseManagerId("")
		}
	}, [prevCreateReferralData, createReferralData])

	if (loading) return <Loader />
	if (error) {
		return (
			<div className='w-full h-full p-6 pt-20'>
				<Note type='error' message='Failed to load referral' />
			</div>
		)
	}

	let buttonClass = "hover:bg-extendedPurple-dark"
	if (
		!name.trim() ||
		!email.trim() ||
		!phone.trim() ||
		!policyNumber.trim() ||
		!status.trim()
	) {
		buttonClass += " bg-extendedCharcoal-three"
	} else {
		buttonClass += " bg-primary-purple"
	}

	const handleSubmit = async event => {
		event.preventDefault()
		if (!caseManagerId) {
			toast.warn("Please select a case manager")
			return
		}
		if (
			!name.trim() ||
			!email.trim() ||
			!phone.trim() ||
			!policyNumber.trim() ||
			!status.trim()
		) {
			toast.warn("Please fill in the details")
		} else {
			try {
				await newReferral({
					variables: {
						input: {
							name,
							email,
							phone,
							policyNumber,
							status,
							notes,
							caseManagerId
						}
					}
				})
				// Show success
				toast.success("Referral has been created!")
			} catch (error) {
				toast.error(error.graphQLErrors[0].message)
				console.error(error)
			}
		}
	}

	return (
		<Fragment>
			<ReactTooltip effect='solid' place='bottom' className='max-w-md' />
			<div className='w-full h-full p-6 pt-8 font-body'>
				<h1 className='py-2 text-2xl text-primary-charcoal'>
					New Referral
				</h1>
				<div className='mt-4 md:mt-8 w-full md:w-2/3 max-w-md'>
					<p className='mb-4 text-left text-lg text-primary-charcoal'>
						Case Manager
					</p>
					<select
						className='w-full bg-gray-300 outline-none px-2 py-1 rounded-sm shadow-inner text-primary-charcoal'
						value={caseManagerId}
						onChange={event =>
							setCaseManagerId(event.target.value)
						}>
						<option value=''>Select manager</option>
						{allManagersData &&
							allManagersData.caseManagers.map(m => (
								<option key={m.id} value={m.id}>
									{m.name}
								</option>
							))}
					</select>
				</div>
				<div className='mt-4 md:mt-10 w-full md:w-2/3 max-w-md '>
					<p className='mb-4 text-left text-lg text-primary-charcoal capitalize'>
						Referral details
					</p>
					<form className='' onSubmit={handleSubmit}>
						<div className='bg-white shadow-md rounded px-4 py-4'>
							<TextInput
								required
								placeholder={`Referral's name`}
								name='name'
								label='Name'
								value={name}
								handleOnChange={setName}
							/>
							<TextInput
								required
								placeholder={`Referral's email`}
								type='email'
								name='email'
								label='Email'
								value={email}
								handleOnChange={setEmail}
							/>
							<TextInput
								asPhone
								required
								placeholder={`Referral's phone number`}
								name='phone'
								label='Phone'
								value={phone}
								handleOnChange={setPhone}
							/>
							<TextInput
								required
								dataTip={`Policy number should be a unique number`}
								placeholder={`Add policy number`}
								name='policy'
								label='Policy number'
								value={policyNumber}
								handleOnChange={setPolicyNumber}
							/>

							<div className=''>
								<label
									htmlFor={name}
									className='mb-1 text-extendedCharcoal-one block'>
									Status at intake
								</label>
								<select
									className='w-full mb-4 px-2 py-1 rounded shadow-inner bg-backgrounds-fadedPurple text-primary-charcoal'
									value={status}
									onChange={({target: {value}}) =>
										setStatus(value)
									}>
									{STATUS_TYPES.map(type => (
										<option
											key={type.id}
											value={type.value}>
											{type.label}
										</option>
									))}
								</select>
							</div>
							<TextInput
								dataTip={`Anything you would like to tell the Uprise coach about the referral.
								 It could be about their need, past behaviour or something 
								 they could be struggling with`}
								placeholder={`Anything you would like to tell the Uprise coach about ...`}
								name='notes'
								label='Notes'
								value={notes}
								handleOnChange={setNotes}
							/>
						</div>

						<button
							className={`w-full h-10 mt-4 rounded-lg shadow-md text-white capitalize ${buttonClass}`}>
							Create referral
						</button>
					</form>
				</div>
			</div>
		</Fragment>
	)
}
