import React from "react"
import PropTypes from "prop-types"
import Icon from "../assets/icons"

export default function Note({type, message}) {
	let iconType = "checkmark"
	let bgClass = "border-l-4"
	let textClass = ""

	if (type === "success") {
		bgClass += ` bg-green-200 border-green-800`
		textClass += ` text-green-800`
		iconType = "checkmark"
	} else if (type === "error") {
		bgClass += ` bg-red-200 border-red-800`
		textClass += ` text-red-800`
		iconType = "cross"
	} else if (type === "warning") {
		bgClass += ` bg-yellow-200 border-yellow-800`
		textClass += ` text-yellow-800`
		iconType = "exclamation"
	} else {
		bgClass += ` border-secondary-electricBlue`
	}

	return (
		<div
			className={`w-full max-w-md flex items-center px-4 py-2 shadow ${bgClass}`}>
			<Icon type={iconType} className={textClass} />
			<p className={`ml-2 text-lg font-body ${textClass}`}>{message}</p>
		</div>
	)
}

Note.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
}
