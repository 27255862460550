import React from "react"
import {useQuery} from "@apollo/react-hooks"
import {gql} from "apollo-boost"
import ReactTooltip from "react-tooltip"

import DashboardSection from "../components/DashboardSection"
import Graph from "../components/Graph"
import Card from "../components/Card"
import Loader from "../components/Loader"
import Note from "../components/Note"

const DASHBOARD = gql`
	{
		dashboard {
			signupGraph {
				points
			}
			referralGraph {
				points
			}
			engagement {
				coachingCalls
				wellbeingChecks
				modulesCompleted
				downstreamReferrals
				signups
				completedModulesByName
			}
			effectiveness {
				preProgram
				midProgram
				postProgram
				followUp
			}
			postProgramMetric {
				nps
				backToWork
			}
		}
	}
`
export default function DashboardView() {
	const {loading, error, data} = useQuery(DASHBOARD)
	if (loading) return <Loader />
	if (error) {
		return (
			<div className='w-full h-full p-6 pt-20 overflow-auto'>
				<Note type='error' message={`Failed to load dashboard`} />
			</div>
		)
	}
	const names = Object.keys(data.dashboard.engagement.completedModulesByName)
	return (
		<div className='w-full h-full p-6 pt-20 overflow-auto'>
			<ReactTooltip effect='solid' place='bottom' className='max-w-md' />
			<DashboardSection title='Program health'>
				<div className='flex flex-col lg:flex-row'>
					<div className='w-full lg:w-1/2 mb-16 lg:mb-0'>
						<p className='mb-2 text-center text-md text-extendedCharcoal-one'>
							Number of Referrals
						</p>
						<Graph
							data={data.dashboard.referralGraph.points}
							primary
						/>
					</div>
					<div className='w-full lg:w-1/2'>
						<p className='mb-2 text-center text-md text-extendedCharcoal-one'>
							Number of Signups
						</p>
						<Graph data={data.dashboard.signupGraph.points} />
					</div>
				</div>
			</DashboardSection>
			<DashboardSection
				title='Program engagement'
				subTitle='program lifetime'>
				<div className='flex flex-wrap'>
					<Card
						className='mr-4'
						label='coaching calls'
						value={data.dashboard.engagement.coachingCalls}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Modules completed by users'
						value={data.dashboard.engagement.modulesCompleted}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Wellbeing checks completed'
						value={data.dashboard.engagement.wellbeingChecks}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Downstream referrals'
						value={data.dashboard.engagement.downstreamReferrals}
						type={"secondary"}
					/>
				</div>
				<div className='flex flex-wrap'>
					<Card
						className='mr-4'
						label='Signups'
						value={data.dashboard.engagement.signups}
						small
						primary
					/>
					{names.map(name => {
						const value =
							data.dashboard.engagement.completedModulesByName[
								name
							]
						return (
							<Card
								className='mr-4'
								key={name}
								label={`${name} Completed Module`}
								value={value}
								small
								primary
							/>
						)
					})}
				</div>
			</DashboardSection>
			<DashboardSection title='Program Effectiveness'>
				<div className='flex flex-wrap'>
					<Card
						className='mr-4'
						label='Pre-Program score'
						value={data.dashboard.effectiveness.preProgram}
						type={"primary"}
						percentage
					/>
					<Card
						className='mr-4'
						label='Mid-Program score'
						value={data.dashboard.effectiveness.midProgram}
						type={"primary"}
						percentage
					/>
					<Card
						className='mr-4'
						label='Post-Program score'
						value={data.dashboard.effectiveness.postProgram}
						type={"primary"}
						percentage
					/>
					<Card
						className='mr-4'
						label='Follow up score'
						value={data.dashboard.effectiveness.followUp}
						type={"secondary"}
						percentage
					/>
				</div>
			</DashboardSection>
			<DashboardSection title='Post Program Metrics'>
				<div className='flex flex-wrap'>
					<Card
						className='mr-4'
						label='Program NPS 15% Users who completed the program and are back to work	102	Coaching calls 205 Modules completed by Users 40 Downstream Referrals Calculated as a Net Promoter Score by subtracting the percentage of detractors from percentage of promoters.'
						value={data.dashboard.postProgramMetric.nps}
						type={"primary"}
						percentage
					/>
					<Card
						className='mr-4'
						label='Users who completed the program and are back to work'
						value={data.dashboard.postProgramMetric.backToWork}
						type={"primary"}
						percentage
					/>
				</div>
			</DashboardSection>
		</div>
	)
}
