import React from "react"
import {useQuery} from "@apollo/react-hooks"
import {gql} from "apollo-boost"
import {useParams} from "react-router-dom"
import DashboardSection from "../components/DashboardSection"
import Card from "../components/Card"
import UserRow from "../components/UserRow"
import TableHeader from "../components/TableHeader"
import Loader from "../components/Loader"
import Note from "../components/Note"
import {USER_TABLE_COLUMNS} from "../utils/constants"

const MANAGER = gql`
	query getManager($id: ID!) {
		caseManagerById(id: $id) {
			id
			name
			email
			phone
			referrals {
				id
				name
				email
				status
				policyNumber
				referralDate
				user {
					id
					name
					programCompletionDate
					referralDate
					signupDate
					modulesCompleted {
						title
					}
					bookingCount
				}
			}
			signups
			userMetric {
				numberOfCoachingCalls
				numberOfModulesCompleted
				wellbeingChecksCompleted
				downstreamReferrals
			}
		}
	}
`
export default function CaseManagerView() {
	const params = useParams()
	const {loading, error, data} = useQuery(MANAGER, {
		variables: {id: params.id}
	})
	if (loading) return <Loader />
	if (error) {
		return (
			<div className='w-full h-full p-6 pt-20 overflow-auto'>
				<Note type='error' message={`Failed to load case manager`} />
			</div>
		)
	}
	const caseManager = data && data.caseManagerById

	return (
		<div className='w-full h-full p-6 pt-8 overflow-auto'>
			<DashboardSection
				title={`${caseManager.name}'s referrals`}
				titleClasses='text-left font-semibold'>
				<div className='flex flex-wrap'>
					<Card
						className='mr-4'
						label='coaching calls'
						value={caseManager.userMetric.numberOfCoachingCalls}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Modules completed by users'
						value={caseManager.userMetric.numberOfModulesCompleted}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Wellbeing checks completed'
						value={caseManager.userMetric.wellbeingChecksCompleted}
						type={"primary"}
					/>
					<Card
						className='mr-4'
						label='Downstream referrals'
						value={caseManager.userMetric.downstreamReferrals}
						type={"secondary"}
					/>
				</div>
			</DashboardSection>

			<div>
				{caseManager.referrals.length ? (
					<table className='overflow-x-auto'>
						<TableHeader columns={USER_TABLE_COLUMNS} />
						<tbody>
							{caseManager.referrals.map(referral => {
								const {user} = referral
								return (
									<UserRow
										key={referral.id}
										policyNumber={referral.policyNumber}
										id={referral.id}
										name={referral.name}
										referralDate={referral.referralDate}
										signupDate={
											user ? user.signupDate : undefined
										}
										modulesCompleted={
											user
												? user.modulesCompleted
												: undefined
										}
										completionDate={
											user
												? user.programCompletionDate
												: undefined
										}
									/>
								)
							})}
						</tbody>
					</table>
				) : (
					<Note
						type='warning'
						message={`${caseManager.name} has not referred anyone yet.`}
					/>
				)}
			</div>
		</div>
	)
}
