import React from "react"
import {useHistory} from "react-router-dom"
import PropTypes from "prop-types"

export default function CaseManagerRow({
	id,
	rank,
	name,
	referrals,
	signups,
	programCompleted
}) {
	const history = useHistory()
	const even = rank % 2 === 0
	return (
		<tr
			className={`font-body ${
				even ? "bg-backgrounds-white" : "bg-backgrounds-fadedPurple"
			}`}>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{rank + 1}
			</td>
			<td
				className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body cursor-pointer hover:text-extendedPurple-one'
				onClick={() => history.push(`/managers/${id}`)}>
				{name}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{referrals.length}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{signups}
			</td>
			<td className='inline-block w-40 py-2 px-4 text-left text-md text-primary-charcoal font-body'>
				{programCompleted}
			</td>
		</tr>
	)
}

CaseManagerRow.propTypes = {
	id: PropTypes.string.isRequired,
	rank: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	referrals: PropTypes.array.isRequired,
	signups: PropTypes.number.isRequired,
	programCompleted: PropTypes.bool
}
