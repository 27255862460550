import React from "react"

export default function Loader() {
	return (
		<div className='w-full flex justify-center'>
			<img
				className='w-10 h-10 mt-24'
				src={require("../assets/icons/circle-spin-purple.svg")}
				alt='loader'
			/>
		</div>
	)
}
