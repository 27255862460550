import React from "react"
import PropTypes from "prop-types"

export default function DashboardSection({
	title,
	subTitle,
	children,
	titleClasses
}) {
	return (
		<div className='mb-16 font-body'>
			<h2
				className={`text-3xl capitalize text-primary-charcoal ${titleClasses}`}>
				{title}
			</h2>
			<p className='text-lg capitalize text-extendedCharcoal-two'>
				{subTitle}
			</p>
			<div className='mt-10'>{children}</div>
		</div>
	)
}

DashboardSection.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.object.isRequired,
	titleClasses: PropTypes.string
}

DashboardSection.defaultProps = {
	titleClasses: "text-left"
}
