import React from "react"
import PropTypes from "prop-types"

export default function TherapistNote(props) {
	return (
		<div className='w-full max-w-lg ml-4'>
			<div className='relative p-6 rounded shadow bg-white flex'>
				<img
					src={require("../assets/images/purple.png")}
					className='absolute w-8 top-0 right-0 mt-2 mr-2'
					alt='comma'
					style={{transform: "rotate(180deg)"}}
				/>
				<img
					src={
						props.therapist &&
						props.therapist.photos &&
						props.therapist.photos.imageURL
					}
					className='w-20 h-20 rounded-full object-cover'
					alt={props.therapist && props.therapist.name}
				/>
				<div className='ml-8'>
					<p className='mb-2 pb-2 border-b border-extendedPurple-one font-body italic text-primary-charcoal'>
						{props.therapistNote
							? props.therapistNote.content
							: "None"}
					</p>
					<p className='text-primary-purple'>
						{props.therapist && props.therapist.name}
					</p>
					<p className='text-extendedCharcoal-one text-sm'>
						Therapist
					</p>
					<p className='text-extendedCharcoal-one uppercase text-sm'>
						Uprise
					</p>
				</div>
			</div>
		</div>
	)
}

TherapistNote.propTypes = {
	therapist: PropTypes.any,
	therapistNote: PropTypes.any
}
