import React from "react"
import PropTypes from "prop-types"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const hintIcon = require("../assets/icons/information-outline.svg")

export default function TextInput({
	name,
	type,
	label,
	value,
	handleOnChange,
	placeholder,
	dataTip,
	required,
	asPhone
}) {
	return (
		<div className='mb-4'>
			<div className='mb-1 flex items-center justify-between'>
				<label
					htmlFor={name}
					className='text text-extendedCharcoal-one block'>
					{label}
					{required && <span>*</span>}
					{!required && (
						<span className='text-sm text-extendedCharcoal-two'>
							{" "}
							(Optional)
						</span>
					)}
				</label>
				{dataTip && (
					<img
						src={hintIcon}
						alt='hint'
						className='w-4 h-4'
						data-tip={dataTip}
					/>
				)}
			</div>
			{asPhone && (
				<PhoneInput
					country={"au"}
					onlyCountries={["au"]}
					disableDropdown
					value={value}
					onChange={phone => handleOnChange(phone)}
				/>
			)}
			{!asPhone && (
				<input
					id={name}
					name={name}
					type={type ? type : "text"}
					required={required}
					value={value}
					onChange={event => handleOnChange(event.target.value)}
					className='w-full pb-2 text-md text-gray-700 border-b border-extendedPurple-five leading-tight focus:outline-none'
					placeholder={placeholder}
				/>
			)}
		</div>
	)
}

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	handleOnChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	dataTip: PropTypes.string,
	asPhone: PropTypes.bool,
	required: PropTypes.bool
}
