import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import CaseManagerRow from "../components/CaseManagerRow"
import TableHeader from "../components/TableHeader"
import { MANAGER_TABLE_COLUMNS } from "../utils/constants"
import Loader from "../components/Loader"
import Note from "../components/Note"

const ALL_MANAGERS = gql`
    {
        caseManagers {
            id
            name
            referrals {
                name
            }
            signups
            programCompleted
        }
    }
`

export default function AllManagersView() {
    const { loading, error, data } = useQuery(ALL_MANAGERS)

    if (loading) return <Loader />
    if (error) {
        return (
            <div className="w-full h-full p-6 pt-20">
                <Note type="error" message={`Failed to load user`} />
            </div>
        )
    }

    return (
        <div className="w-full h-full p-6 pt-8 overflow-auto">
            <h1 className="text-2xl text-primary-charcoal">
                All Case Managers
            </h1>
            <div className="mt-16 overflow-x-auto">
                <table>
                    <TableHeader columns={MANAGER_TABLE_COLUMNS} />
                    <tbody>
                        {data.caseManagers
                            .sort(sortBy)
                            .map((manager, index) => (
                                <CaseManagerRow
                                    key={manager.id}
                                    rank={index}
                                    {...manager}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function sortBy(a, b, options = { asc: false, key: "referrals" }) {
    if (options.asc) {
        return a[options.key].length - b[options.key].length
    } else {
        return b[options.key].length - a[options.key].length
    }
}
